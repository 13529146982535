import React, { useEffect } from "react";
import Slider from "../slider/Slider";
import About from "../about/About";
import "./home.css";
import Energy from "../energy/Energy";
import Services from "../services/Services";
import SecondSlider from "../secondSlider/SecondSlider";
import Projects from "../projects/Projects";
import Contact from "../contact/Contact";
import { Element } from "react-scroll";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Element name="slider">
        <Slider />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="energy">
        <Energy />
      </Element>
      <Element name="services">
        <Services />
      </Element>
      <Element name="projects">
        <Projects />
      </Element>
      <Element name="secondSlider">
        <SecondSlider />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
    </>
  );
};

export default Home;
