import React, { useState } from "react";
import {
  FaBirthdayCake,
  FaCoffee,
  FaLaptop,
  FaLifeRing,
  FaServicestack,
  FaSuitcase,
  FaWifi,
} from "react-icons/fa";
import "./projects.css";

const Projects = () => {
  const [tab1, set1Tab] = useState(true);
  const [tab2, set2Tab] = useState(false);
  const [tab3, set3Tab] = useState(false);

  const hideTab2 = () => {
    set1Tab(true);
    set2Tab(false);
    set3Tab(false);
  };

  const hideTab1 = () => {
    set1Tab(false);
    set2Tab(true);
    set3Tab(false);
  };

  const hideTab3 = () => {
    set1Tab(false);
    set2Tab(false);
    set3Tab(true);
  };

  return (
    <div className="projectContainer">
      <p className="findHere">-----------Find Here-----------</p>
      <h1 style={{ color: "rgb(21,59,104)" }}>All Our Projects</h1>
      <div className="buttonDivs">
        <button
          style={{ border: tab1 ? "1px solid black" : "none" }}
          onClick={hideTab2}
        >
          Solar Projects
        </button>
        <button
          style={{ border: tab2 ? "1px solid black" : "none" }}
          onClick={hideTab1}
        >
          Wind Projects
        </button>
        <button
          style={{ border: tab3 ? "1px solid black" : "none" }}
          onClick={hideTab3}
        >
          Hydro Electric Projects
        </button>
      </div>
      <div className="projectTabsContainer">
        {tab1 && (
          <div className="tab1div">
            <h5>EPC Projects</h5>
            <div className="firstRow">
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-american-public-power-association-513dBrMJ_5w-unsplash.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">7.8MWp DC/6.5 MW AC</h5>
                  <p className="card-text">
                    Ground Mounted SPP at Koppal Dst, Karnataka.
                  </p>
                </div>
              </div>
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-american-public-power-association-XGAZzyLzn18-unsplash-1.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">4.2 MWp DC/3.5MW AC</h5>
                  <p className="card-text">
                    Ground Mounted SPP at Kovilpatti Dst, Tamil Nadhu.
                  </p>
                </div>
              </div>
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-nuno-marques-0GbrjL3vZF4-unsplash-1.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">4.8 MWp DC/4 MW AC</h5>
                  <p className="card-text">
                    Ground Mounted SPP at Bagealli Dst, Karnataka
                  </p>
                </div>
              </div>
            </div>
            <h5>IPP Projects (In-House)</h5>
            <div className="secondRow">
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="./2023-10-ipps.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">24MWp DC/20 MW AC</h5>
                  <p className="card-text">
                    Ground Mounted SPP at Indupur Dst, Andra Pradesh.
                  </p>
                </div>
              </div>
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="./2023-10-residential-solar.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Retail Solar for Domestic Application
                  </h5>
                  <p className="card-text">
                    Solar PV systems installed in 2020 and 2021 are eligible for
                    a 26% tax credit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab2 && (
          <div className="tab2div">
            <h5>EPC Projects</h5>
            <div className="firstRow">
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-Renovables_746x419.jpeg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">243 MWp DC/202.5 MW AC</h5>
                  <p className="card-text">
                    Wind project at Gadhag and Koppal Dst. Karnataka.
                  </p>
                </div>
              </div>
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-thomas-reaubourg-JRUVbgJJTBM-unsplash.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">146.4 MWp DC/122 MW AC</h5>
                  <p className="card-text">
                    Wind project at Bijapur Dst. Karnataka.
                  </p>
                </div>
              </div>
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-Renovables_746x419.jpeg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">213.6 MWp DC / 178 MW AC</h5>
                  <p className="card-text">
                    Wind project at Bijapur Dst. Karnataka. (Pipeline)
                  </p>
                </div>
              </div>
            </div>
            <h5>IPP Projects (In-House)</h5>
            <div className="secondRow">
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-peter-beukema-OovEfuIwBOM-unsplash.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">350KWp DC /250 KW AC</h5>
                  <p className="card-text">
                    Wind project Marathwada auto cluster Aurangabad Dst,
                    Maharastra.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab3 && (
          <div className="tab3div">
            <h5>EPC Projects</h5>
            <div className="firstRow">
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-istockphoto-1193865075-612x612-1.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Hydro electric energy</h5>
                  <p className="card-text"></p>
                </div>
              </div>
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-istockphoto-139913615-612x612-1.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Hydro electric power junction</h5>
                  <p className="card-text"></p>
                </div>
              </div>
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-istockphoto-640916644-612x612-1.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Hydro power junction</h5>
                  <p className="card-text"></p>
                </div>
              </div>
            </div>
            <h5>IPP Projects (In-House)</h5>
            <div className="secondRow">
              <div className="card" style={{ width: "21rem", height: "28rem" }}>
                <img
                  src="/2023-10-istockphoto-1193865075-612x612-1.jpg"
                  className="card-img-top"
                  alt="solar"
                  style={{ height: "17rem" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Hydro electric energy</h5>
                  <p className="card-text"></p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
