import React from "react";
import { Link } from "react-scroll";
import "./footer.css";

const Footer = () => {
  return (
    <div className="mainContainer">
      <div className="footerContainer">
        <div className="miniFooters">
          <h5>FA POWER</h5>
          <Link
            to="home"
            smooth={true}
            duration={500}
            className="navbar-brand"
            offset={-100}
            style={{ cursor: "pointer" }}
          >
            <img src="/2023-10-Logo-Jpeg-01.jpg" alt="" />
          </Link>
        </div>
        <div className="miniFooters">
          <h5>Navigation</h5>
          <Link
            to="home"
            smooth={true}
            duration={500}
            className="nav-link"
            offset={-500}
          >
            Home
          </Link>
          <Link
            to="about"
            smooth={true}
            duration={500}
            className="nav-link"
            offset={-150}
          >
            About Us
          </Link>
          <Link
            to="services"
            smooth={true}
            duration={500}
            className="nav-link"
            offset={-100}
          >
            Services
          </Link>
          <Link
            to="projects"
            smooth={true}
            duration={500}
            className="nav-link"
            offset={-100}
          >
            Projects
          </Link>
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="nav-link"
            offset={-150}
          >
            Contact Us
          </Link>
        </div>
        <div className="miniFooters">
          <h5>Reach Us Here....! </h5>
          <p>No. 225, DBS House No. 26, Cunningham Road, Bangalore 560052</p>
        </div>
        <div className="miniFooters">
          <h5>Any Queries!</h5>
          <p>Please send mail Here…!</p>
          <div className="submitBtn">
            <input type="text" />
            <button>submit</button>
          </div>
        </div>
      </div>
      <div className="copyright">
        © COPYRIGHT 2023 POWERED BY
        <span>
          <a href="https://www.ganakalabs.com/" style={{ color: "white" }}>
            {" "}
            GANAKALABS.COM
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
