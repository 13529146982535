import React from "react";
import { FaSolarPanel, FaWind, FaWater } from "react-icons/fa";
import "./energy.css";

const Energy = () => {
  return (
    <div className="energyContainer">
      <p className="findHere">-----------Find Here-----------</p>
      <h1 style={{ color: "rgb(21,59,104)" }}>All Our Energy Resources</h1>
      <div className="innerEnergy">
        <div className="cardDiv">
          <FaSolarPanel
            color="rgb(124,176,50)"
            fontSize="3.2em"
            className="energyImg"
          />
          <p className="energyHeading">Solar Energy</p>
          <p className="energyPara">
            Harnessing the Sun's Energy for a Sustainable Tomorrow. We offer
            end-to-end solutions, leveraging cutting-edge technology and
            sustainable practices.
          </p>
        </div>
        <div className="cardDiv">
          <FaWind
            color="rgb(124,176,50)"
            fontSize="3.2em"
            className="energyImg"
          />
          <p className="energyHeading">Wind Energy</p>
          <p className="energyPara">
            Sustainable Energy from Every Breeze. Our commitment to wind energy
            includes site selection, permits, design, installation, and ongoing
            maintenance. We harness the power of the wind for a cleaner, greener
            future.
          </p>
        </div>
        <div className="cardDiv">
          <FaWater
            color="rgb(124,176,50)"
            fontSize="3.2em"
            className="energyImg"
          />
          <p className="energyHeading">Hydro Electric Energy</p>
          <p className="energyPara">
            Hydroelectric Energy. Using the strength of flowing water to
            generate renewable electricity. Join us in the journey towards a
            greener, more sustainable future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Energy;
