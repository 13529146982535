import React from "react";
import "./slider.css";

const Slider = () => {
  return (
    <div className="sliderContainer">
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
        data-interval="2500"
      >
        <div className="carousel-inner">
          <div className="carousel-item active sliderItem ">
            <div className="imageText">
              <h2>Wind Renewable Energy</h2>
              <p>"Wind Unleashed: A Fresh Breeze to a Greener Tomorrow"</p>
            </div>
            <img
              className="d-block w-100"
              src="/2023-10-appolinary-kalashnikova-WYGhTLym344-unsplash.jpg"
              alt="First slide"
            />
          </div>
          <div className="carousel-item sliderItem">
            <div className="imageText">
              <h2>Hydro Electric Energy</h2>
              <p>"Embracing Water's Power: Unleash hydroelectric power"</p>
            </div>
            <img
              className="d-block w-100"
              src="/2023-10-mark-konig-eZuXKn1lULM-unsplash.jpg"
              alt="Second slide"
            />
          </div>
          <div className="carousel-item sliderItem">
            <div className="imageText">
              <h2>Renewable Energy Sources</h2>
              <p>"Revolution of Energy for our and future Generation"</p>
            </div>
            <img
              className="d-block w-100"
              src="/2023-10-Renewable.jpg"
              alt="Third slide"
            />
          </div>
          <div className="carousel-item sliderItem">
            <div className="imageText">
              <h2>Solar Renewable Energy</h2>
              <p>"Solar Brilliance for Sustainable Future"</p>
            </div>
            <img
              className="d-block w-100"
              src="/2023-10-american-public-power-association-513dBrMJ_5w-unsplash.jpg"
              alt="Third slide"
            />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};

export default Slider;
