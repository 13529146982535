import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { FaFacebookF } from "react-icons/fa";
import {
  AiOutlineTwitter,
  AiFillLinkedin,
  AiOutlineDribbble,
  AiOutlineInstagram,
} from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";
import { TbMailUp } from "react-icons/tb";
import "./navbar.css";

const Navbar = () => {
  const [mobileView, setMobileView] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [clickedLink, setClickedLink] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 993);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className="stickyNavbar">
      <div className="topContainer">
        <div className="navBar">
          <div className="icons">
            <span>
              <FaFacebookF />
            </span>
            <span>
              <AiOutlineTwitter />
            </span>
            <span>
              <AiFillLinkedin />
            </span>
            <span>
              <AiOutlineDribbble />
            </span>
            <span>
              <AiOutlineInstagram />
            </span>
          </div>

          {/* Contact Icons */}
          <div className="contacts">
            <div className="icons">
              <span>
                <IoCallOutline />
              </span>
              <p className="contactPara">+91 95387 31555</p>
            </div>
            <div className="icons">
              <span>
                <TbMailUp />
              </span>
              <p className="contactPara">info@fapower.co.in</p>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar bg-body-tertiary">
        <div className="container">
          <div className="titleName">
            <Link
              to="home"
              smooth={true}
              duration={500}
              className="navbar-brand"
              offset={-500}
              style={{ cursor: "pointer" }}
            >
              <img
                className="brandLogo"
                src="/2023-10-Logo-PNG-01-01-01.png"
                alt="Bootstrap"
                width="30"
                height="24"
              />
            </Link>
            FA POWER
          </div>
          {mobileView && (
            <button className="mobileMenuBtn" onClick={toggleMenu}>
              <span className="lines"></span>
              <span className="lines"></span>
              <span className="lines"></span>
            </button>
          )}
          {!mobileView && (
            <ul className="nav justify-content-end normalScreenListItems">
              <li className="nav-item">
                <Link
                  to="home"
                  smooth={true}
                  duration={500}
                  className={
                    clickedLink === "home" ? "nav-link colorActive" : "nav-link"
                  }
                  offset={-500}
                  onClick={() => setClickedLink("home")}
                >
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="about"
                  smooth={true}
                  duration={500}
                  className={
                    clickedLink === "about"
                      ? "nav-link colorActive"
                      : "nav-link"
                  }
                  offset={-130}
                  onClick={() => setClickedLink("about")}
                >
                  ABOUT US
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="services"
                  smooth={true}
                  duration={500}
                  className={
                    clickedLink === "services"
                      ? "nav-link colorActive"
                      : "nav-link"
                  }
                  offset={-50}
                  onClick={() => setClickedLink("services")}
                >
                  SERVICES
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="projects"
                  smooth={true}
                  duration={500}
                  className={
                    clickedLink === "projects"
                      ? "nav-link colorActive"
                      : "nav-link"
                  }
                  offset={-50}
                  onClick={() => setClickedLink("projects")}
                >
                  PROJECTS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="contact"
                  smooth={true}
                  duration={500}
                  className={
                    clickedLink === "contactus"
                      ? "nav-link colorActive"
                      : "nav-link"
                  }
                  offset={-150}
                  onClick={() => setClickedLink("contactus")}
                >
                  CONTACT US
                </Link>
              </li>
            </ul>
          )}

          {mobileView && (
            <ul
              className={`${showMobileMenu ? "mobileMenuItems" : "dontShow"}`}
            >
              <button className="closeButton" onClick={toggleMenu}>
                <IoIosCloseCircleOutline />
              </button>
              <li className="nav-item">
                <Link
                  to="home"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  offset={-500}
                  onClick={toggleMenu}
                >
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="about"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  offset={-100}
                  onClick={toggleMenu}
                  activeClass="highlightClass" // Change this line
                >
                  ABOUT US
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="services"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  offset={-50}
                  onClick={toggleMenu}
                  activeClass="highlightClass" // Change this line
                >
                  SERVICES
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="projects"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  offset={-50}
                  onClick={toggleMenu}
                  activeClass="highlightClass" // Change this line
                >
                  PROJECTS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="contact"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  offset={-150}
                  onClick={toggleMenu}
                  activeClass="highlightClass" // Change this line
                >
                  CONTACT US
                </Link>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
