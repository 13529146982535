import React, { Component } from "react";
import Slider from "react-slick";
import "./secondSlider.css";

export default class AutoPlayMethods extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    var settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 966,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 617,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div style={{ padding: "3% 10%" }}>
        <h1
          style={{
            color: "rgb(21,59,104)",
            textAlign: "center",
            paddingBottom: "1em",
          }}
        >
          Our Clients
        </h1>
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          <div className="imgContainer">
            <img src="./1.png" alt="1" />
          </div>
          <div className="imgContainer">
            <img src="./2.png" alt="2" />
          </div>
          <div className="imgContainer">
            <img src="./3.png" alt="3" />
          </div>
          <div className="imgContainer">
            <img src="./4.png" alt="4" />
          </div>
          <div className="imgContainer">
            <img src="./5.jpg" alt="5" />
          </div>
          <div className="imgContainer">
            <img src="./6.png" alt="6" />
          </div>
          <div className="imgContainer">
            <img src="./7.jpg" alt="7" />
          </div>
        </Slider>
      </div>
    );
  }
}
