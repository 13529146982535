import React, { useState } from "react";
import "./about.css";

const About = () => {
  const [showMoreText1, setShowMoreText1] = useState(false);
  const [showMoreText2, setShowMoreText2] = useState(false);

  const toggleShowMoreText1 = () => {
    setShowMoreText1(!showMoreText1);
  };

  const toggleShowMoreText2 = () => {
    setShowMoreText2(!showMoreText2);
  };

  return (
    <div className="aboutContainerParent">
      <h1 className="ah1">About Us</h1>
      <div className="aboutContainer">
        <div className="leftContainer">
          <div>
            <h4 style={{ color: "rgb(21, 58, 105)" }}>
              FA POWER RENEWABLES PVT LTD
            </h4>
            <p className="para">
              We are an independent power producing company. We belive, the sun,
              wind, air, and biogas are not just natural elements but the
              building blocks of our future’s sustainable enegry generation. In
              an era where the need for renewable energy is paramount, FA Power
              leads the way in crafting, innovative turnkey solutions to drive
              the transition from fossil fuel to renewable sources.With a
              relentless commitment for providing comprehensive project
              management. we breathe life into wind, solar, and hybrid projects,
              ensuring they flourish concept of commissioning.
            </p>
            {showMoreText1 && (
              <p>
                A startup in renewable energy, we embrace the boundless
                potential of these replenishing sources, unlike their finite,
                non-renewable counterparts. FA Power’s journey, was started in
                the year 2021 as an independent power producer (ipp) , has
                evolved into a captivating narrative of environmental
                stewardship and energy excellence. Come join us as we redefine
                the future, with renewable energy.
              </p>
            )}
            <div>
              <button onClick={toggleShowMoreText1}>More</button>
            </div>
          </div>
        </div>
        <div className="rightContainer">
          <div className="colorDiv">
            <img src="/2023-10-chelsea-WvusC5M-TM8-unsplash.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className="aboutContainer2">
        <div className="rightContainer">
          <div className="colorDiv">
            <img src="/2023-10-chelsea-WvusC5M-TM8-unsplash.jpg" alt="" />
          </div>
        </div>
        <div className="leftContainer">
          <div>
            <h4 style={{ color: "rgb(21, 58, 105)" }}>
              FA INFRA AND RENEWABLES
            </h4>
            <p className="para">
              FA Infra And Renewables (FAIR) is a dynamic turkey developer of
              renewable Energy projects since 2016, based at Bangalore,
              Karnataka. Specialization in the turnkey development of Wind,
              Solar, and Hydro Electric Power Plants. The company collaborates
              with clients to determine their captive generation capacities,
              considering factors such as load and roof availability. Leveraging
              the robust network and strong relationships with suppliers, policy
              makers, bureaucrats and distribution companies, FAIR ensures
              expedited results. The core engineering team boasts over 100 years
              of cumulative experience in the renewable energy sector, having
              successfully executed multi megawatt capacity projects.
            </p>
            {showMoreText2 && (
              <p>
                The team's Techno-Liaison expertise secures timely commissioning
                of the projects. The company's vision is to deliver
                best-in-class, Techno-Commercially viable, tailor made Renewable
                Power Projects on a turnkey basis, to Suit customer
                requirements. Our mission is to inspire customers to become an
                indepeneded Power producer, by directly contributing to the
                reduction of CO2 emissions. With a highly experienced
                engineering and finance team, in-house project design, a global
                supply chain presence in China, and a robust sub-vendor network
                across India, FA Infra And Renewables delivers the projects in a
                very short lead time which benifits the coustomes.
              </p>
            )}
            <div>
              <button onClick={toggleShowMoreText2}>More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
