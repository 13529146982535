import React, { useState } from "react";
import {
  FaLaptop,
  FaServicestack,
  FaSuitcase,
  FaMapMarkedAlt,
  FaKey,
} from "react-icons/fa";
import { GrLicense } from "react-icons/gr";
import { FcApproval } from "react-icons/fc";
import { MdDesignServices } from "react-icons/md";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import "./services.css";

const Services = () => {
  const [tab1, set1Tab] = useState(true);
  const [tab2, set2Tab] = useState(false);

  const hideTab2 = () => {
    set1Tab(true);
    set2Tab(false);
  };
  const hideTab1 = () => {
    set1Tab(false);
    set2Tab(true);
  };

  return (
    <div className="servicesContainer">
      <p className="findHere">-----------Find Here-----------</p>
      <h1 style={{ color: "rgb(21,59,104)" }}>All Our Services</h1>
      <div>
        <button
          style={{ border: tab1 ? "1px solid black" : "none" }}
          onClick={hideTab2}
        >
          EPC Services
        </button>
        <button
          style={{ border: tab2 ? "1px solid black" : "none" }}
          onClick={hideTab1}
        >
          IPP Services
        </button>
      </div>
      {tab1 && (
        <div className="tabContainer">
          <div className="miniTab">
            <FaMapMarkedAlt className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>Land </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Finding the right location for your renewable energy project is
                crucial for its success. Carefully evaluate the potential site
                for environmental impact, accessibility, and other factors.
              </p>
            </div>
          </div>
          <div className="miniTab">
            <FaKey className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>Licensing </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Obtain the necessary licenses and permits to legally operate
                your renewable energy project. Navigate the complex regulatory
                landscape with expert guidance to ensure compliance.
              </p>
            </div>
          </div>
          <div className="miniTab">
            <FcApproval className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>Permits & Approvals </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Secure necessary permits and approvals from government agencies
                to ensure the project's legality and compliance. Navigating the
                regulatory landscape can be complex, so seek expert guidance.
              </p>
            </div>
          </div>

          <div className="miniTab">
            <MdDesignServices className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>Project Designing </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Conceptualize and design the renewable energy plant to optimize
                efficiency, performance, and cost-effectiveness. Consider
                factors like the type of renewable energy resource, generator
                size, and power transmission infrastructure.
              </p>
            </div>
          </div>
          <div className="miniTab">
            <AiOutlineFundProjectionScreen className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>
                Project Establishment{" "}
              </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Construct the physical infrastructure of the renewable energy
                project, including the energy conversion system, transmission
                lines, and ancillary equipment. Employ skilled contractors and
                implement rigorous quality control measures to ensure project
                integrity.
              </p>
            </div>
          </div>
          <div className="miniTab">
            <FaSuitcase className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>
                Project commissioning{" "}
              </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Test and integrate the renewable energy system into the power
                grid to ensure it operates safely and reliably. Conduct
                comprehensive performance tests to validate the system's
                capacity and efficiency.
              </p>
            </div>
          </div>
        </div>
      )}
      {tab2 && (
        <div className="tabContainer2">
          <div className="miniTab">
            <FaServicestack className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>Power Sales </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Optimize your renewable energy project's revenue by maximizing
                power output and securing favorable sales agreements. Gain
                expertise in the complex renewable energy market and navigate
                regulatory requirements with ease.
              </p>
            </div>
          </div>
          <div className="miniTab">
            <FaLaptop className="miniTabImg" />
            <div className="miniTabP">
              <h6 style={{ color: "rgb(21,58,105)" }}>Assest Management </h6>
              <p style={{ color: "rgb(152,152,153)" }}>
                Enhance the lifespan and performance of your renewable energy
                assets through comprehensive asset management strategies.
                Minimize downtime and maximize return on investment with
                proactive maintenance and optimization techniques.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
