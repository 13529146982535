import React from "react";
import "./contact.css";
import {
  FaEnvelopeOpen,
  FaFacebookF,
  FaGooglePlusG,
  FaInstagram,
  FaLocationDot,
  FaTwitter,
} from "react-icons/fa6";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="contactContainer">
      <div className="leftContainer">
        <div className="upperDiv">
          <p style={{ fontWeight: "bold", color: "rgb(21,58,105)" }}>
            WE'D LOVE TO HEAR FROM YOU
          </p>
          <h1 style={{ color: "rgb(21,58,105)" }}>Contact Us</h1>
        </div>
        <div className="lowerDiv">
          <div className="leftLowerDiv">
            <div>
              <p className="reachusthrough" style={{ color: "rgb(243,6,7)" }}>
                {" "}
                REACH US THROUGH
              </p>
            </div>
            <div className="location">
              <FaMapMarkerAlt />
              <p>
                No. 225, DBS House No. 26, Cunningham Road, Bangalore 560052
              </p>
            </div>
            <div className="location">
              <FaPhoneAlt />
              <p>+91 95387 31555</p>
            </div>
            <div className="location">
              <FaEnvelopeOpen />
              <p>info@fapower.co.in</p>
            </div>
          </div>
          <div></div>
          <div className="rightLowerDiv">
            <div className="social">
              <FaFacebookF />
            </div>
            <div className="social">
              <FaTwitter />
            </div>
            <div className="social">
              <FaInstagram />
            </div>
            <div className="social">
              <FaGooglePlusG />
            </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <div>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.5em",
              color: "rgb(21,58,105)",
            }}
          >
            Send Us A Message
          </p>
        </div>
        <form>
          <div className="form-group">
            <label
              for="exampleFormControlInput1"
              style={{ color: "rgb(243,6,7)" }}
            >
              Name*
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label
              for="exampleFormControlInput1"
              style={{ color: "rgb(243,6,7)" }}
            >
              Email*
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="example@email.com"
            />
          </div>
          <div className="form-group">
            <label
              for="exampleFormControlInput1"
              style={{ color: "rgb(243,6,7)" }}
            >
              Contact Number*
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Your Contact Number"
            />
          </div>
          <div className="form-group">
            <label
              for="exampleFormControlTextarea1"
              style={{ color: "rgb(243,6,7)" }}
            >
              Your Message*
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Your Message Here"
            ></textarea>
          </div>
          <button type="button">send message</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
